.hero {
  position: relative;
  z-index: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Set the z-index to be behind other elements */
}

.hero-content {
  position: relative; /* Change to relative positioning */
  z-index: 1; /* Set a higher z-index to bring it in front of the video background */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content h1 {
  text-align: center;
  color: #7843e6;
  margin-top: 720px;
  margin-bottom: 2px;
  font-size: 100px;
  transition: transform 0.3s;
  animation: moveText 1s infinite linear; /* Add animation properties */
}

@keyframes moveText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Adjust the distance and direction of movement */
  }
  100% {
    transform: translateY(0);
  }
}


.hero-content h1:hover {
  transform: scale(1.1);
}

.h1-link {
  color: inherit;
  text-decoration: none;
}
