.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0); /* Set background color to transparent */
    color: black; /* Set text color to black */
    padding: 0px;
    z-index: 999;
  }
  
  .logo {
    width: 250px;
    height: 200px;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    margin: 0 100px; /* Add margin to the right and left */
    margin-top: 20px;
  }
  
  .logo:hover {
    transform: scale(1.1); /* Scale the logo on hover */
  }
  
  .menu {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px; /* Adjust margin-top as needed */
  }
  
  .menu li {
    margin-right: 40px; /* Add spacing between menu items */
    cursor: pointer;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    font-size: 42px; /* Increase the font size */
    color: #fff; /* Set text color to purple */
    font-family: Arial, sans-serif; /* Use Arial or any other desired normal font */
    font-weight: bold; /* Set the font weight to bold */
  }
  
  
  
  .menu li:hover {
    transform: translateY(-2px); /* Apply vertical translation on hover */
    color: darkorange; /* Set text color to dark orange on hover */
  }
  
  .menu li a {
    text-decoration: none; /* Remove hyperlink underline */
    color: inherit; /* Inherit parent's color */
  }
  
  /* Rest of the CSS remains the same */
  